<template>
  <div class="my-project-requests-create">
    <div class="container-xl">
      <data-loading :loading="dataLoading" :lock="true"/>
      <form @input="validateForm" @change="validateForm" @submit.stop.prevent="submitForm">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Заявка на привлечение средств</div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="general-name">Наименование</label>
                  <input v-model="request.general.name"
                         class="form-control form-control-lg"
                         id="general-name"
                         type="text"
                         placeholder="Наименование"
                         required>
                  <input-errors :errors="errors['general.name']"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="general-site-link">Сайт или соцсети компании (если имеется)</label>
                  <input v-model="request.general.siteLink"
                         class="form-control form-control-lg"
                         id="general-site-link"
                         type="url"
                         placeholder="https://...">
                  <input-errors :errors="errors['general.siteLink']"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="general-industry">Вид деятельности</label>
                  <input v-model="request.general.industry"
                         class="form-control form-control-lg"
                         id="general-industry"
                         type="text"
                         placeholder="Вид деятельности"
                         required>
                  <input-errors :errors="errors['general.industry']"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="loan-purpose">Цель займа</label>
                  <input v-model="request.loan.purpose"
                         class="form-control form-control-lg"
                         id="loan-purpose"
                         type="text"
                         placeholder="Цель займа"
                         required>
                  <input-errors :errors="errors['loan.purpose']"/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="contact-person-name">Контактное лицо</label>
                  <input v-model="request.contactPerson.name"
                         class="form-control form-control-lg"
                         id="contact-person-name"
                         type="text"
                         placeholder="Контактное лицо"
                         required>
                  <input-errors :errors="errors['contactPerson.name']"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="contact-person-phone">Телефон</label>
                  <input-mask v-model="request.contactPerson.phone"
                              mask="+7 (999) 999-9999"
                              class="form-control form-control-lg"
                              id="contact-person-phone"
                              placeholder="+7 (000) 000-0000"
                              required/>
                  <input-errors :errors="errors['contactPerson.phone']"/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="loan-amount">Сумма займа</label>
                  <div class="input-group">
                    <input-money v-model="request.loan.amount"
                                 class="form-control form-control-lg"
                                 id="loan-amount"
                                 placeholder="10 000 000"
                                 required/>
                    <div class="input-group-append">
                      <div class="input-group-text">руб</div>
                    </div>
                  </div>
                  <input-errors :errors="errors['loan.amount']"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="loan-term">Срок займа</label>
                  <div class="input-group">
                    <input v-model="request.loan.term"
                           class="form-control form-control-lg"
                           id="loan-term"
                           type="number"
                           min="1"
                           max="36"
                           step="1"
                           placeholder="36"
                           required>
                    <div class="input-group-append">
                      <div class="input-group-text">мес</div>
                    </div>
                  </div>
                  <input-errors :errors="errors['loan.term']"/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="h6">Обеспечение</div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="collateral-object-type">Тип обьекта</label>
                  <select class="form-control form-control-lg"
                          id="collateral-object-type"
                          autocomplete="off"
                          required
                          @change = "handleSelect"
                  >
                    <option>Квартира</option>
                    <option>Коммерческое помещение</option>
                    <option>Промышленный объект</option>
                    <option>Земельный участок</option>
                    <option>Дом</option>
                    <option>Другое</option>
                  </select>
                  <input-errors :errors="errors['collateral.objectType']"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="text-muted" for="collateral-location">Место нахождения объекта</label>
                  <input v-model="request.collateral.location"
                         class="form-control form-control-lg"
                         id="collateral-location"
                         type="text"
                         placeholder="Место нахождения объекта"
                         required>
                  <input-errors :errors="errors['collateral.location']"/>
                </div>
              </div>
            </div>
            <div class="form-group text-right mt-3">
              <button type="submit" class="btn btn-lg btn-success">
                Подать заявку
              </button>
            </div>
          </div>
        </div>
      </form>
      <processing :show="processing"/>
    </div>
  </div>
</template>

<script>
import { CompanySearch, DataLoading, InputErrors, InputMoney, Processing } from "@/components/common";
import InputMask from "primevue/inputmask";
import { DashboardEventType } from "../../../enums/DashboardEventType";
import { FundingRequestManager } from "../managers/FundingRequestManager";

const fundingRequestManager = new FundingRequestManager();

export default {
  name: "Create",
  components: {
    CompanySearch,
    DataLoading,
    InputErrors,
    InputMask,
    InputMoney,
    Processing
  },
  inject: ["profile"],
  mounted() {
    if (this.profile.company) {
      this.request.general.name = this.profile.company.name;
      this.request.contactPerson.name = this.profile.company.contactPerson;
    }
  },
  data() {
    return {
      request: {
        general: {},
        contactPerson: {},
        loan: {},
        collateral: {},
      },
      errors: {},
      dataLoading: false,
      processing: false
    };
  },
  methods: {
    handleSelect(e){
      this.request.collateral.objectType = e.target.value;
      this.validateForm();
    },
    validateForm() {
      const errors = {};
      if (!this.request.general.name) {
        errors['general.name'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.general.industry) {
        errors['general.industry'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.loan.purpose) {
        errors['loan.purpose'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.contactPerson.name) {
        errors['contactPerson.name'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.contactPerson.phone) {
        errors['contactPerson.phone'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.loan.amount) {
        errors['loan.amount'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.loan.term) {
        errors['loan.term'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.collateral.objectType) {
        errors['collateral.objectType'] = ["Поле обязательное для заполнения"];
      }
      if (!this.request.collateral.location) {
        errors['collateral.location'] = ["Поле обязательное для заполнения"];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          const { uuid } = await fundingRequestManager.create(this.request);
          this.$emit("dashboardEvent", DashboardEventType.ProjectRequestCreated);
          await this.$router.push({ name: "myProjectRequests.request.general", params: { uuid } });
        } finally {
          this.processing = false;
        }
      }
    }
  }
}
</script>

<style lang="scss">
.my-project-requests-create {
  .card {
    .card-body:not(:nth-child(2)) {
      border-top: 0.125rem solid #ecedef;
    }
  }
}
</style>
