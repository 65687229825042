import { Money } from "@/utils/api-client";
import { Phone } from "@/utils/api-client/models/common/Phone";
import { UuidResult } from "@/utils/api-client/models/common/UuidResult";
import { createProjectRequestManager } from "@/utils/di";

type FundingRequest = {
  readonly general: {
    readonly name: string;
    readonly siteLink?: string;
    readonly industry: string;
  };
  readonly contactPerson: {
    readonly name: string;
    readonly phone: string;
  };
  readonly loan: {
    readonly purpose: string;
    readonly amount: string;
    readonly term: number;
  };
  readonly collateral: {
    readonly objectType: string;
    readonly location: string;
  };
}

export class FundingRequestManager {
  async create({ contactPerson, loan, ...requestData }: FundingRequest): Promise<UuidResult> {
    const { phone, ...contactPersonData } = contactPerson;
    const { amount, ...loanData } = loan;
    const phoneCountryCode = "7";
    const phoneNumber = phone.replace(/\D/g, "").substr(1);
    const manager = await createProjectRequestManager();
    return manager.create({
      ...requestData,
      contactPerson: {
        ...contactPersonData,
        phone: new Phone(phoneCountryCode, phoneNumber)
      },
      loan: {
        ...loanData,
        sum: new Money(
          parseInt(amount) * 100,
          "RUB"
        )
      }
    });
  }
}
